import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {isJSON} from '@commonFunction';
import toast, {Toaster} from 'react-hot-toast';
import {BaseComponent} from '@baseComponent';
import {classNames} from '@commonFunction';
import {contactmetaAddupdate, revealEmail} from '@myContactsActions';
import LocaleStrings from '../../../languages';
import {TRIALPLANID} from '../../../../common/constants';

import iconvalid from '../../../../assets/images/mycontacts/valid.png';
import iconunknown from '../../../../assets/images/mycontacts/unknown.png';

import iconvalidinfo from '../../../../assets/images/mycontacts/iconvalid.png';
import iconunknowninfo from '../../../../assets/images/mycontacts/iconunknown.png';
import BusinessContact from './businesscontact';
import Personalcontact from './personalcontact';
import {parse} from '../../../../common/common-utils';

const IconImageSize = 16;

class MyContactsListitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      selectedUsers: [],
      contactData: props.values,
      revealingPersonal: false,
      revealingBusiness: false,
      revealingContact: false,
      showBusinessEmailInfo: false,
      hoveredBusinessEmail: '',
      clientPos: null,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }
  // checkbox for select user
  selectionCallback = user => {
    // var user = this.props.appUser;
    this.props.checkAndUncheckUsers(user);
  };

  // copy email/phone
  _handleCopy(e, val, mainvalue, type) {
    if (type == 'email') {
      // console.log("val", val);
      if (!val.address) {
        navigator.clipboard.writeText(val);
      } else {
        navigator.clipboard.writeText(val.address);
      }
      // navigator.clipboard.writeText(val.address);
    } else {
      navigator.clipboard.writeText(val);
    }

    toast.success('Copied');
  }

  // email upvoting
  _handleemailupvote(e, val, mainvalue) {
    this.props.updateContactMeta(val.address, 'upvote', 'personalemail');
  }

  // email downvoting
  _handleemaildownvote(e, val, mainvalue) {
    this.props.updateContactMeta(val.address, 'downvote', 'personalemail');
  }

  // phone upvote
  _handlephoneupvote(e, val, mainvalue) {
    this.props.updateContactMeta(val, 'upvote', 'phone');
  }

  // phone downvote
  _handlephonedownvote(e, val, mainvalue) {
    this.props.updateContactMeta(val, 'downvote', 'phone');
  }
  // linkedin redirection
  openLinkedin(e, val) {
    if (val !== '') {
      if (!val.startsWith('https://www.')) {
        window.open('https://www.' + val, '_blank');
      } else {
        window.open(val, '_blank');
      }
      // window.open("https://" + val, "_blank");
    }
  }
  // rendering phone no
  renderphoneList = (phonenumberArr, mainvalue) => {
    let item = _.map(
      phonenumberArr.filter(
        phnum =>
          Object.prototype.toString.call(phnum) === '[object String]' &&
          phnum !== '',
      ),
      (val, index) => {
        if (index < 3) {
          return (
            <div className="flex items-center space-x-4">
              {/* <div className="flex-1 min-w-0">
              <p className="text-sm font-thin text-gray500">{val}</p>
            </div> */}
              <div class="group flex cursor-pointer relative">
                <div class=" px-2 py-1 ">{val}</div>
                <div
                  class="group-hover:opacity-100 opacity-0 transition-opacity px-1 right-0  
                -translate-x-1/2 translate-y-full mx-auto">
                  <div className="">
                    <span className="isolate inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        onClick={e =>
                          this._handleCopy(e, val, mainvalue, 'phone')
                        }
                        className="relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none ">
                        <img
                          height={IconImageSize}
                          width={IconImageSize}
                          src="../../images/copysmall.png"></img>
                      </button>
                      <button
                        type="button"
                        onClick={e =>
                          this._handlephoneupvote(e, val, mainvalue)
                        }
                        className="relative -ml-px inline-flex items-center border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 ">
                        <img
                          height={IconImageSize}
                          width={IconImageSize}
                          src="../../images/like.png"></img>
                      </button>
                      <button
                        type="button"
                        onClick={e =>
                          this._handlephonedownvote(e, val, mainvalue)
                        }
                        className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50">
                        <img
                          height={IconImageSize}
                          width={IconImageSize}
                          src="../../images/dislike.png"></img>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      },
    );
    return item;
  };
  // rendering email
  renderemailList = (emailArr, mainvalue) => {
    let item = _.map(emailArr, (val, index) => {
      if (index < 3) {
        return (
          <Personalcontact
            key={val.contactid}
            mainvalue={mainvalue}
            val={val}
            onUpvote={val => {
              this.props.updateContactMeta(val, 'upvote', 'personalemail');
            }}
            onDownvote={val => {
              this.props.updateContactMeta(val, 'downvote', 'personalemail');
            }}
          />
        );
      }
    });
    return item;
  };
  // rendering business email
  renderbusinessemailList = (emailArr, mainvalue) => {
    let item = _.map(emailArr, (val, index) => {
      return (
        <BusinessContact
          key={val.contactid}
          mainvalue={mainvalue}
          val={val}
          onUpvote={val => {
            this.props.updateContactMeta(val, 'upvote', 'businessemail');
          }}
          onDownvote={val => {
            this.props.updateContactMeta(val, 'downvote', 'businessemail');
          }}
        />
      );
    });
    return item;
  };

  handleindex = nmbr => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  // updgrade plan page redirection
  _openUpgradPlan(e) {
    this.props.onUpgradePlan();
  }

  // addon page redirection
  _handleAddon(e) {
    this.props.onBuyAddon();
  }

  render() {
    var {keyindex, appuserData} = this.props;
    let {contactData} = this.state;

    var selectedUsers = this.props.selectedUsers;
    var index = _.findIndex(selectedUsers, function (curuser) {
      return curuser.contactid === contactData.contactid;
    });

    var checked = false;
    if (index >= 0) {
      checked = true;
    }
    let phoneArr = [];
    let emailArr = [];

    const parsedEmail =
      contactData.emails !== null
        ? parse(contactData.emails, this.props.session.sessionToken)
        : null;

    let emailsjson = isJSON(parsedEmail); // checking valid json

    const parsedPhone =
      contactData.phone_numbers !== null
        ? parse(contactData.phone_numbers, this.props.session.sessionToken)
        : null;

    let phonejson = isJSON(parsedPhone); // checking valid json

    if (emailsjson) {
      emailArr = JSON.parse(parsedEmail);
      emailArr = emailArr.filter(email => email.type !== 'business');
    }

    if (phonejson) {
      phoneArr = JSON.parse(parsedPhone);
    }

    let indexisodd1 = this.handleindex(keyindex);

    let business_emails = '';
    const parsedBusinessEmails =
      contactData.business_emails != null
        ? parse(contactData.business_emails, this.props.session.sessionToken)
        : null;
    if (parsedBusinessEmails && parsedBusinessEmails != null) {
      business_emails = _.split(parsedBusinessEmails, ',');
    }

    let business_emailcredits = 0;
    let personal_emailcredits = 0;
    let contactcredits = 0;

    let planid = '';
    let sku = '';
    let role = '';

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      role = appuserData.data[0].role;

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        planid = subscriptionplan_by_planid.planid;
      }

      if (
        appuserData &&
        appuserData.data &&
        appuserData.data.length > 0 &&
        appuserData.data[0].client_by_clientid
      ) {
        if (
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid
            .length > 0
        ) {
          let usercredit_by_clientid =
            appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];

          contactcredits = usercredit_by_clientid.contactcredit;
          business_emailcredits = usercredit_by_clientid.bus_email_credits;
          personal_emailcredits = usercredit_by_clientid.personal_email_credits;
        }
      }
    }

    let phoneNums =
      parsedPhone && parsedPhone !== 'null'
        ? JSON.parse(parsedPhone.replace(/'/g, '"'))
        : [];

    let showPhoneText = '';

    if (phoneNums.length > 0) {
      const extra = phoneNums.length > 1 ? ` +${phoneNums.length - 1}` : '';
      phoneNums = phoneNums.slice(0, 1);
      showPhoneText = `${LocaleStrings.mycontacts_reveal} ${phoneNums.join(
        ',',
      )} ${extra}`;
    } else {
      showPhoneText = `${LocaleStrings.mycontacts_reveal} +**********`;
    }

    let emails =
      parsedEmail && parsedEmail !== 'null'
        ? JSON.parse(parsedEmail.replace(/'/g, '"'))
        : [];

    let showPersonalEmailText = '';

    let personalEmails = emails.filter(contact => contact.type === 'personal');

    if (personalEmails.length > 0) {
      const extra =
        personalEmails.length > 1 ? ` +${personalEmails.length - 1}` : '';
      personalEmails = personalEmails.slice(0, 1);
      const emails = personalEmails.map(contact => contact.address).join(',');
      showPersonalEmailText = `${LocaleStrings.mycontacts_reveal} ${emails} ${extra}`;
    } else {
      showPersonalEmailText = `${LocaleStrings.mycontacts_reveal} *****@****.***`;
    }

    let showBusinessEmailText = '';

    let businessEmails = emails.filter(contact => contact.type === 'business');

    if (businessEmails.length > 0) {
      const extra =
        businessEmails.length > 1 ? ` +${businessEmails.length - 1}` : '';
      businessEmails = businessEmails.slice(0, 1);
      const emails = businessEmails.map(contact => contact.address).join(',');
      showBusinessEmailText = `${LocaleStrings.mycontacts_reveal} ${emails} ${extra}`;
    } else {
      showBusinessEmailText = `${LocaleStrings.mycontacts_reveal} *****@****.***`;
    }

    return (
      <>
        <tr
          className={classNames(
            indexisodd1 ? 'bg-white relative' : 'chatbg relative',
            'table table-fixed w-full',
          )}>
          <td className="relative w-12 px-8">
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
              onChange={() => this.selectionCallback(this.state.contactData)}
              checked={checked}
            />
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
            {contactData.name}
            <div
              className="text-sm text-primary font-medium cursor-pointer"
              onClick={e => this.openLinkedin(e, contactData.linkedinurl)}>
              {LocaleStrings.mycontacts_linkedinprofile}
            </div>
          </td>
          <td
            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
            style={{width: '20%'}}>
            {phoneArr.length > 0 && contactData.phonecost === 1 ? (
              <span>{this.renderphoneList(phoneArr, contactData)}</span>
            ) : (
              ''
            )}
            {contactcredits > 0 ? (
              contactData.phonecost === 0 ? (
                <button
                  style={{width: 'auto', fontSize: 14}}
                  type="button"
                  onClick={() => {
                    this.setState({revealingContact: true});
                    this.props.revealEmail(
                      this.props.session,
                      {
                        linkedinurl: contactData.linkedinurl,
                        type: 'directdial',
                        action: 'reveal',
                      },
                      response => {
                        console.log('Contact REVEALED => ', response);

                        if (response.success === 1) {
                          const newContactData = response.result.contactdata[0];
                          const contactData = {
                            ...this.state.contactData,
                            phone_numbers: newContactData.phone_numbers,
                            phonecost: 1,
                          };

                          this.setState({contactData, revealingContact: false});
                        } else {
                          const newContactData = {
                            ...this.state.contactData,
                            phonecost: 1,
                          };
                          const contactData = {
                            ...this.state.contactData,
                            ...newContactData,
                          };
                          this.setState({contactData, revealingContact: false});
                        }
                      },
                    );
                  }}
                  className={classNames('btn-primary ml-2')}>
                  <svg
                    className={classNames(
                      this.state.revealingContact ? '' : 'sr-only',
                      'animate-spin-medium h-5 w-5 rounded-full mx-2',
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {`${showPhoneText}`}
                </button>
              ) : phoneArr.length === 0 ? (
                LocaleStrings.nophone
              ) : (
                ''
              )
            ) : contactData.phonecost === 0 ? (
              <button
                type="button"
                className="btn-primary ml-4 xs:mt-4 md:mt-0  items-center"
                style={{width: 'auto', fontSize: 14}}
                onClick={e => {
                  // if (role === 'client' && planid != TRIALPLANID)
                  //   this._handleAddon(e);
                  // else this._openUpgradPlan(e);
                  this._openUpgradPlan(e);
                }}>
                {LocaleStrings.upgrade}
                {/* {role === 'client' && planid != TRIALPLANID
                  ? 'Buy Addon'
                  : 'Upgrade'} */}
              </button>
            ) : phoneArr.length === 0 ? (
              LocaleStrings.nophone
            ) : (
              ''
            )}
          </td>
          <td
            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
            style={{width: '30%'}}>
            {business_emails.length > 0 &&
            contactData.businessemailcost === 1 ? (
              <span>
                {this.renderbusinessemailList(business_emails, contactData)}
              </span>
            ) : (
              ''
            )}
            {/* {business_emails == '' ? '-' : ''} */}
            {business_emailcredits > 0 ? (
              contactData.businessemailcost === 0 ? (
                <button
                  style={{fontSize: 14, width: 'auto'}}
                  type="button"
                  onClick={() => {
                    this.setState({revealingBusiness: true});
                    this.props.revealEmail(
                      this.props.session,
                      {
                        linkedinurl: contactData.linkedinurl,
                        type: 'businessemail',
                        action: 'reveal',
                      },
                      response => {
                        console.log('Business REVEALED => ', response);

                        if (response.success === 1) {
                          const newContactData = response.result.contactdata[0];
                          const contactData = {
                            ...this.state.contactData,
                            business_emails: newContactData.business_emails,
                            businessemailcost: 1,
                          };

                          this.setState({
                            contactData,
                            revealingBusiness: false,
                          });
                        } else {
                          const newContactData = {
                            ...this.state.contactData,
                            businessemailcost: 1,
                          };
                          const contactData = {
                            ...this.state.contactData,
                            ...newContactData,
                          };
                          this.setState({
                            contactData,
                            revealingBusiness: false,
                          });
                        }
                      },
                    );
                  }}
                  className={classNames('btn-primary ml-2')}>
                  <svg
                    className={classNames(
                      this.state.revealingBusiness ? '' : 'sr-only',
                      'animate-spin-medium h-5 w-5 rounded-full mx-2',
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {showBusinessEmailText}
                </button>
              ) : business_emails == '' ? (
                LocaleStrings.noemail
              ) : (
                ''
              )
            ) : contactData.businessemailcost === 0 ? (
              <button
                type="button"
                className="btn-primary ml-4 xs:mt-4 md:mt-0  items-center"
                style={{width: 'auto', fontSize: 14}}
                onClick={e => this._openUpgradPlan(e)}>
                {'Upgrade'}
              </button>
            ) : business_emails == '' ? (
              LocaleStrings.noemail
            ) : (
              ''
            )}
          </td>
          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Social</td> */}
          <td
            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
            style={{width: '30%'}}>
            {emailArr.length > 0 && contactData.personalemailcost === 1 ? (
              <span>{this.renderemailList(emailArr, contactData)}</span>
            ) : (
              ''
            )}

            {/* {emailArr.length == 0 ? '-' : ''} */}
            {personal_emailcredits > 0 ? (
              contactData.personalemailcost === 0 ? (
                <button
                  type="button"
                  style={{fontSize: 14, width: 'auto'}}
                  onClick={() => {
                    this.setState({revealingPersonal: true});
                    this.props.revealEmail(
                      this.props.session,
                      {
                        linkedinurl: contactData.linkedinurl,
                        type: 'personalemail',
                        action: 'reveal',
                      },
                      response => {
                        if (response.success === 1) {
                          const newContactData = response.result.contactdata[0];
                          const contactData = {
                            ...this.state.contactData,
                            emails: newContactData.emails,
                            personalemailcost: 1,
                          };

                          this.setState({
                            contactData,
                            revealingPersonal: false,
                          });
                        } else {
                          const newContactData = {
                            ...this.state.contactData,
                            personalemailcost: 1,
                          };
                          const contactData = {
                            ...this.state.contactData,
                            ...newContactData,
                          };
                          this.setState({
                            contactData,
                            revealingPersonal: false,
                          });
                        }
                      },
                    );
                  }}
                  className={classNames('btn-primary ml-2')}>
                  <svg
                    className={classNames(
                      this.state.revealingPersonal ? '' : 'sr-only',
                      'animate-spin-medium h-5 w-5 rounded-full mx-2',
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {showPersonalEmailText}
                </button>
              ) : emailArr.length === 0 ? (
                LocaleStrings.noemail
              ) : (
                ''
              )
            ) : contactData.personalemailcost === 0 ? (
              <button
                type="button"
                className="btn-primary ml-4 xs:mt-4 md:mt-0  items-center"
                style={{width: 'auto', fontSize: 14}}
                onClick={e => this._openUpgradPlan(e)}>
                {'Upgrade'}
              </button>
            ) : emailArr.length === 0 ? (
              LocaleStrings.noemail
            ) : (
              ''
            )}
          </td>

          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-2/12">
            {business_emails.length > 0 ? (
              <span>{this.renderbusinessemailList(business_emails)}</span>
            ) : (
              "-"
            )}
          </td> */}

          {/* <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium" /> */}
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user, appuserData} = state;

  return {
    session,
    user,
    appuserData,
  };
}

export default connect(mapStateToProps, {
  contactmetaAddupdate,
  revealEmail,
})(MyContactsListitems);
