import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import {getContact, searchUrl} from '../actions';
import BaseLoader from '@baseLoader';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import ContactCredits from './contactcredits';
import NoContact from './no-contact';
import ReportContactPopup from './reportcontactpopup';
import {contactmetaAddupdate} from '@myContactsActions';
import {appConstant} from '../../../../common/common-utils';

class MainSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      personalChecked: true,
      businessChecked: true,
      phoneChecked: true,
      searchText: '',
      searchedData: null,
      isFetching: false,
      expired: false,
      openFlagPopup: false,
      selectedContact: undefined,
      mightHaveData: false,
    };

    // All Binded Functions
  }
  componentDidMount() {
    this.checkSubscription(subscribed => {});
  }

  validated = (personal, business, phone) => {
    const {searchText} = this.state;
    if (!personal && !business && !phone) {
      toast.error('Please choose options');
      return false;
    }
    if (searchText.indexOf('linkedin.com') === -1) {
      toast.error('Please input correct url');
      return false;
    }
    return true;
  };

  handleSearch = (personal, business, phone) => {
    if (this.validated(personal, business, phone)) {
      appConstant.kFetchedBusinessEmail = false;
      appConstant.kFetchedPersonalEmail = false;
      appConstant.kFetchedPhone = false;

      this.setState({isFetching: true, mightHaveData: false});
      this.props.searchUrl(
        this.props.session,
        {
          linkedinurl: this.state.searchText.trim(),
          personal,
          business,
          phone,
        },
        response => {
          if (response.success === 1) {
            this.setState({
              isFetching: false,
              searchedData:
                response.result.length > 0 ? response.result[0] : null,
              mightHaveData: true,
            });
          } else {
            this.setState({isFetching: false, mightHaveData: true});
          }
        },
      );
    }
  };

  checkSubscription = callback => {
    if (
      this.props.currentUser &&
      this.props.currentUser.session_token &&
      this.props.currentUser.usersubscription_by_clientid &&
      this.props.currentUser.usersubscription_by_clientid.length
    ) {
      let usersubscription =
        this.props.currentUser.usersubscription_by_clientid[0];
      if (usersubscription.isactive === 1) {
        if (
          this.props.currentUser.usercredit_by_clientid &&
          this.props.currentUser.usercredit_by_clientid.length &&
          this.props.currentUser.usercredit_by_clientid[0].contactcredit +
            this.props.currentUser.usercredit_by_clientid[0].emailcredit >
            0
        ) {
          this.setState({expired: false});
        } else {
          this.setState({expired: true});
        }
      } else {
        this.setState({expired: true});
      }
      callback && callback(true);
    } else {
      this.setState({expired: true});
      callback && callback(false);
    }
  };

  updateContactMeta = (val, action, type, downvotedata) => {
    let obj = {};
    obj.linkedin_url = this.state.searchText;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    this.props.contactmetaAddupdate(this.props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  render() {
    const {
      personalChecked,
      businessChecked,
      phoneChecked,
      searchedData,
      expired,
      searchText,
      mightHaveData,
    } = this.state;

    return (
      <div className="lg:px-2">
        <div>
          <div className="flex flex-row">
            <div className="w-1/3">
              <div className="py-4 gap-8 items-center bg-white p-4 rounded-lg border-2 border-gray-100">
                <div className="text-mainGray text-base font-semibold mb-2">
                  {'Search by Linkedin URL'}
                </div>
                <div className="flex flex-col gap-4 py-2">
                  <span>Linkedin URL</span>
                  <input
                    id="search-field"
                    className="border-gray-300 xs:w-full sm:w-full h-full pl-4 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                    placeholder={'Search by URL'}
                    type="search"
                    name="search"
                    value={searchText}
                    onChange={e => {
                      this.setState({searchText: e.target.value});
                      if (e.target.value.length === 0) {
                        this.setState({
                          searchedData: null,
                          mightHaveData: false,
                        });
                      }
                    }}
                  />
                </div>
                {/* <fieldset className="space-y-2 flex flex-col py-2">
                  <span>
                    <input
                      id="phonenumber"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                      onChange={e => {
                        this.setState({phoneChecked: e.target.checked});
                        if (this.state.searchedData) {
                          this.handleSearch(
                            this.state.personalChecked,
                            this.state.businessChecked,
                            e.target.checked,
                          );
                        }
                      }}
                      checked={this.state.phoneChecked}
                    />
                    <label htmlFor="phonenumber">Phone Number</label>
                  </span>
                  <span>
                    <input
                      id="businessemail"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                      onChange={e => {
                        this.setState({businessChecked: e.target.checked});
                        if (this.state.searchedData) {
                          this.handleSearch(
                            this.state.personalChecked,
                            e.target.checked,
                            this.state.phoneChecked,
                          );
                        }
                      }}
                      checked={this.state.businessChecked}
                    />
                    <label htmlFor="businessemail">Work Email</label>
                  </span>
                  <span>
                    <input
                      id="personalemail"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                      onChange={e => {
                        this.setState({personalChecked: e.target.checked});
                        if (this.state.searchedData) {
                          this.handleSearch(
                            e.target.checked,
                            this.state.businessChecked,
                            this.state.phoneChecked,
                          );
                        }
                      }}
                      checked={this.state.personalChecked}
                    />
                    <label htmlFor="personalemail">Secondary Email</label>
                  </span>
                </fieldset> */}
                <div className="flex mt-4 items-center justify-center px-10">
                  <button
                    type="button"
                    className={classNames(
                      'items-center',
                      personalChecked || businessChecked || phoneChecked
                        ? 'btn-primary hover:opacity-90'
                        : 'btn-disabled ',
                    )}
                    disabled={
                      !(personalChecked || businessChecked || phoneChecked)
                    }
                    onClick={e => {
                      this.handleSearch(
                        this.state.personalChecked,
                        this.state.businessChecked,
                        this.state.phoneChecked,
                      );
                    }}>
                    {'Search'}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'justify-center bg-white rounded-lg border-2 border-gray-100 w-2/3 ml-4',
              )}>
              {this.state.isFetching ? (
                <BaseLoader data={'Fetching details...'} />
              ) : searchedData ? (
                <ContactCredits
                  expired={expired}
                  showPersonal={personalChecked}
                  showBusiness={businessChecked}
                  showPhone={phoneChecked}
                  history={this.props.history}
                  linkedinurl={searchText}
                  updateContactMeta={(val, action, type) => {
                    if (action === 'upvote') {
                      this.updateContactMeta(val, action, type);
                    } else {
                      this.setState({
                        openFlagPopup: true,
                        selectedContact: {contact: val, contacttype: type},
                      });
                    }
                  }}
                />
              ) : (
                <NoContact validProfileUrl={searchText && mightHaveData} />
              )}
            </div>
          </div>
        </div>
        <Toaster />
        <ReportContactPopup
          open={this.state.openFlagPopup}
          {...this.state.selectedContact}
          onClose={() => {
            this.setState({openFlagPopup: false});
          }}
          onSubmit={data => {
            this.updateContactMeta(
              this.state.selectedContact.contact,
              'downvote',
              this.state.selectedContact.contacttype,
              data,
            );
            this.setState({openFlagPopup: false});
          }}
        />
      </div>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  currentUser: {...state.user, session_token: state.session},
});

export default connect(mapStateToProps, {
  searchUrl,
  getContact,
  contactmetaAddupdate,
})(MainSearch);
