import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import _ from 'lodash';
import renderHTML from 'react-render-html';
import LocaleStrings from '@language';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
// Import constant
import {validateEmail, validatepassword} from '@constant';
import {
  resetStore,
  resgisterUser,
  validateEmailForAppUser,
  sendVerificationemail,
  registrationConfirmationemail,
  validateDomain,
} from '@mainActions';
import {logGTMEvent} from '@commonFolder/gtm-event';
import LoginSideBar from '../../common/loginsidebar';
import SocialLogin from '../sociallogin/index';

class Register extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      show: false,
      show1: false,
      isTncClicked: false,
      phone: '',
      country: '',
      // T&C button is clicked or not
    };

    // All Bind Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);

    // console.log('freeEmailDomains => ', freeEmailDomains);
  }

  componentDidMount() {}

  UNSAFE_componentWillMount() {
    this.setState({
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      show: false,
      show1: false,
    });
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      show: false,
      show1: false,

      isTncClicked: false, // T&C button is clicked or not
    });
  }

  changeShow() {
    let {show} = this.state;
    this.setState({show: !show});
  }

  changeShow1() {
    let {show1} = this.state;
    this.setState({show1: !show1});
  }

  getCookieValue(name) {
    const pattern = `; ${name}=`;
    const cookieString = `; ${document.cookie}`;
    const parts = cookieString.split(pattern);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  }

  getDecodedCookieValue(name) {
    const value = this.getCookieValue(name);
    return value ? decodeURIComponent(value) : null;
  }

  /* FORM SUBMIT */
  onFormSubmit(values) {
    let {isTncClicked} = this.state;
    if (values.hasOwnProperty('email')) {
      values.email = values.email.toLowerCase();
    }
    if (values.email) {
      this.setState({isBtnDisable: true});
      this.setState({btnLoader: true});
      this.props.validateDomain(
        this.props.session,
        {email: values.email},
        response => {
          if (
            response.result &&
            response.success === 1 &&
            response.result.valid === 1
          ) {
            if (values.password && values.password.length < 8) {
              this.setState({btnLoader: false});
              this.setState({isBtnDisable: false});
              toast.error(LocaleStrings.mainsignup_passwordlength_validation);
              return false;
            }
            // TNC check
            if (!isTncClicked) {
              toast.error(LocaleStrings.mainsignup_tc_validation);
              this.setState({isBtnDisable: false});
              this.setState({btnLoader: false});
              return false;
            }
            let emailobj = {};
            emailobj.email = values.email;

            if (values.firstname && values.lastname) {
              values.name =
                values.firstname.trim() + ' ' + values.lastname.trim();
            }
            // delete values["firstname"];
            // delete values["lastname"];
            // return false;

            const currentVisitSourceTime = this.getDecodedCookieValue(
              'k_current_visit_source_time',
            );

            const currentVisitSource = this.getDecodedCookieValue(
              'k_current_visit_source',
            );
            const referralData = this.getDecodedCookieValue('k_referral_data');

            // Output the cookie values to the console
            console.log('Current Visit Source Time:', currentVisitSourceTime);
            console.log('Current Visit Source:', currentVisitSource);
            console.log('Referral Data:', referralData);
            if (currentVisitSourceTime) {
              values.k_current_visit_source_time = currentVisitSourceTime;
            }
            if (currentVisitSource) {
              values.k_current_visit_source = currentVisitSource;
            }
            if (referralData) {
              values.k_referral_data = referralData;
            }

            this.props.validateEmailForAppUser(
              this.props.session,
              values,
              emailresponse => {
                if (
                  emailresponse.result &&
                  emailresponse.success == 1 &&
                  emailresponse.result.available == 1
                ) {
                  this.props.resgisterUser(values, response => {
                    if (response.success === 1) {
                      toast.success(LocaleStrings.login_success);
                      this.setState({isBtnDisable: false});
                      this.setState({btnLoader: false});
                      // this.props.sendVerificationemail(session, emailobj);
                      // this.props.registrationConfirmationemail(session, emailobj);
                      // this.props.history.push('/dashboard/main/dashboardmain');
                      logGTMEvent({
                        event: 'platformCreateAccount',
                      });
                      this.props.history.push('/tellusmore');
                      this.props.reset();
                    } else {
                      // If Un successfull Registration
                      toast.error(LocaleStrings.something_went_wrong);
                      this.setState({isBtnDisable: false});
                      this.setState({btnLoader: false});
                      this.props.reset();
                      logGTMEvent({
                        event: 'platformSignUpErrors',
                        method: 'email',
                        errorMesssage: 'registration failed',
                      });
                      return false;
                    }
                  });
                } else {
                  this.setState({isBtnDisable: false});
                  this.setState({btnLoader: false});
                  toast.error(LocaleStrings.login_error_emailexist);
                  logGTMEvent({
                    event: 'platformSignUpErrors',
                    method: 'email',
                    errorMesssage: 'email already exist',
                  });
                }
              },
            );
          } else {
            this.setState({isBtnDisable: false});
            this.setState({btnLoader: false});
            toast.error(LocaleStrings.business_email_error);
          }
        },
      );
    }
    // let emailExtention = '';
    // if (values.hasOwnProperty('email')) {
    //   values.email = values.email.toLowerCase();
    // }
    // if (values.email) {
    //   emailExtention = values.email.split('@')[1];
    //   isrestrictedEmail = freeEmailDomains.includes(emailExtention);
    //   // var emailextensionExist = _.filter(emailRestrict, (obj) => {
    //   //   return obj === emailExtention;
    //   // });
    //   // if (emailextensionExist.length > 0) {
    //   //   isrestrictedEmail = true;
    //   // }
    // }
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.showModal) {
    //   this.setState({ ...this.state, loading: false });
    // }
  }

  /* Handel tnc button */
  _handelTncBtn = e => {
    this.setState({isTncClicked: e.target.checked}); // Set tnc check box value
  };

  _phoneChange = (value, country, e, formattedValue) => {
    this.setState({phone: value, country: country.dialCode});
  };

  loginsignupToggle = () => {
    this.props.history.push('/login');
  };

  render() {
    var {handleSubmit, btnLoading, language} = this.props;
    var {isTncClicked} = this.state;

    let countryOpts = [];

    _.forEach(this.props.allcountryList, function (value) {
      var obj = {
        name: value.name,
        value: value.name,
      };

      countryOpts.push(obj);
    });

    return (
      <div>
        <div
          className="flex flex-row h-full"
          style={{height: 'calc(100vh / var(--zoom))'}}>
          <div className="main-login justify-center flex flex-col px-10 w-4/6 items-center">
            <div className="w-1/2">
              <form
                className="space-y-2"
                onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                <div className="mb-2">
                  <div className="text-4xl mt-2 font-medium">
                    {LocaleStrings.mainsignup}
                  </div>
                  <div className=" font-normal text-ternary text-lg">
                    {LocaleStrings.mainsignup_subtitle}
                  </div>
                </div>
                <div className="pb-2">
                  <Field
                    name="firstname"
                    label={LocaleStrings.mainsignup_firstname_label}
                    placeholder={LocaleStrings.mainsignup_firstname_placeholder}
                    type="text"
                    component={this.renderFieldText}
                    mandatory="true"
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-base font-normal text-secondary"
                  />
                </div>
                <div className="pb-2">
                  <Field
                    name="lastname"
                    label={LocaleStrings.mainsignup_lastname_label}
                    placeholder={LocaleStrings.mainsignup_lastname_placeholder}
                    type="text"
                    component={this.renderFieldText}
                    mandatory="true"
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-base font-normal text-secondary"
                  />
                </div>
                <div className="pb-2">
                  <Field
                    name="email"
                    label={LocaleStrings.mainsignup_businessemail_label}
                    placeholder={
                      LocaleStrings.mainsignup_businessemail_placeholder
                    }
                    type="text"
                    component={this.renderFieldText}
                    mandatory="true"
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-base font-normal text-secondary"
                  />
                </div>
                <div className="pb-2">
                  <Field
                    name="password"
                    label={LocaleStrings.password}
                    placeholder={LocaleStrings.mainsignup_create_password}
                    type="password"
                    component={this.renderFieldText}
                    mandatory="true"
                    show={this.state.show}
                    setShow={this.changeShow}
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-base font-normal text-secondary"
                    className="cust-pr-20"
                  />
                  {/* <div className="text-gray500 text-base text-normal">{LocaleStrings.mainsignup_password_validate}</div> */}
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-primary focus:ring-custWhite border-gray-300 rounded"
                      onClick={e => this._handelTncBtn(e)}
                    />

                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-base">
                      {LocaleStrings.mainsignup_termsandcondition1}
                    </label>
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-base  underline cursor-pointer text-primary">
                      <a
                        href="https://www.kipplo.com/terms-and-conditions/"
                        target="_blank">
                        {LocaleStrings.mainsignup_termsandcondition2}
                      </a>
                    </label>
                  </div>

                  <div className="text-base"></div>
                </div>
                <div className="flex flex-col pt-4 justify-center gap-2">
                  <button
                    type="submit"
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                      'btn-primary',
                    )}>
                    <svg
                      className={classNames(
                        this.state.btnLoader ? '' : 'sr-only',
                        'animate-spin-medium h-5 w-5 rounded-full mx-2',
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.mainsignup_page2_crtaccbtn}
                  </button>

                  {/* <SocialLoginContainer
                    comesFrom="register"
                    history={this.props.history}
                  /> */}
                  <SocialLogin
                    comesFrom="register"
                    history={this.props.history}
                  />
                </div>

                {/* <div className="pt-4">
                    <button type="button" className="btn-white">
                      <img className="pr-2" src="../../images/google.png"></img> {LocaleStrings.mainsignup_signupgoogle}
                    </button>
                  </div>
                  <div className="pt-4">
                    <button type="button" className="btn-white">
                      <img className="pr-2" src="../../images/linkedin.png"></img> {LocaleStrings.mainsignup_signuplinkedin}
                    </button>
                  </div> */}
                <div className="pt-4 justify-center flex">
                  <div className="mt-2 font-normal text-ternary">
                    {LocaleStrings.mainsignup_alreadyhavingacc}
                  </div>
                  <div
                    className="mt-2 ml-1 font-normal text-primary cursor-pointer"
                    onClick={this.loginsignupToggle}>
                    {LocaleStrings.mainlogin_title}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <LoginSideBar />
        </div>
        <Toaster />
        <div className="absolute top-2 left-4">
          <img
            // className="h-6"
            className="w-40"
            // className="h-12 w-52"
            // src="../../images/mainlogo.png"
            src="../../images/kipplo-logo.webp"></img>
        </div>
      </div>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};
  var requiredFields = [];
  var email = values['email'];
  var password = values['password'];
  // var email = values["email"];
  // var email = values["email"];

  requiredFields = ['email', 'firstname', 'lastname', 'password'];

  requiredFields.forEach(field => {
    if (!values[field] || _.trim(values[field]) === '') {
      errors[field] = LocaleStrings.required;
    }
  });

  if (email && !validateEmail(email)) {
    errors['email'] = 'Invalid email';
  }

  let passwordErrorMessage =
    '<div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One lowercase character</div><div style="display: table-cell;">&#8226; Atleast one number</div></div></div><div style="width: 350px; display: table;margin-top:10px"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One uppercase character</div><div style="display: table-cell;"> &#8226; 8 characters minimum</div></div></div>';
  if (password && !validatepassword(password)) {
    errors['password'] = renderHTML(passwordErrorMessage);
  }

  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  language: state.language,
  allcountryList: state.allcountryList,
  formValues: getFormValues('registerForm')(state),
});

export default reduxForm({
  validate,
  form: 'registerForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    resetStore,
    resgisterUser,
    validateEmailForAppUser,
    sendVerificationemail,
    registrationConfirmationemail,
    validateDomain,
  })(Register),
);
