import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {BaseComponent} from '@baseComponent';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';
import toast from 'react-hot-toast';

class ApiusageItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      btnLoader: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  _handlecopy = (e, apikey) => {
    navigator.clipboard.writeText(apikey);
    toast.success(LocaleStrings.copied);
  };

  handleindex = nmbr => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    var {values, keyindex} = this.props;
    let indexisodd1 = this.handleindex(keyindex);
    return (
      <>
        <tr className={classNames(indexisodd1 ? 'bg-white' : 'chatbg', '')}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm ">
            <div className="text-mainGray font-medium  text-base">GET</div>
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer flex text-center">
            <div className="text-gray-500">{values.apikey}</div>
            {/* <button
              type="button"
             
              className=""> */}
            <img
              src="../../images/copysmall.png"
              onClick={e => this._handlecopy(e, values.apikey)}
              className="ml-2 cursor-pointer"></img>
            {/* </button> */}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-gray-500 text-center">
            {values.apiusage_by_apikey ? values.apiusage_by_apikey.length : 0}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-gray-500 text-center">
            0
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-gray-500 text-center">
            {values.personalemailcount}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-gray-500 text-center">
            {values.businessemailcount}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-gray-500 text-center">
            {values.directdialscount}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {})(ApiusageItem);
