import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import ReactConfirmAlert, {confirmAlert} from 'react-confirm-alert';
import {Switch} from '@headlessui/react';
import toast, {Toaster} from 'react-hot-toast';
import {BaseComponent} from '@baseComponent';
import LocaleStrings from '@language';
import {classNames, converDateIntoLocal} from '@commonFunction';
import {
  fetchMembers,
  updateMember,
  sendEmailtoAppuser,
  deleteMembers,
  sendClientcredit,
} from '@manageseatAction';
import {fetchAppuser} from '@sidebarActions';

class MemberListitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      btnLoader: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  _deleteMember(e, values) {
    let {session, user} = this.props;

    var data = {
      // appuserid: values.appuserid,
      // isdeleted: 1,
      afid: values.afid,
      clientid: values.clientid,
    };
    // console.log("data", data);
    // debugger;
    confirmAlert({
      title: LocaleStrings.setting_manageseats_items_delete_title,
      message: LocaleStrings.setting_manageseats_items_delete_desc,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteMembers(
              this.props.session,
              values.appuserid,
              response => {
                if (response.success == 0) {
                  toast.error(LocaleStrings.common_fail_message);
                } else {
                  this.props.sendClientcredit(session, data, callback => {
                    this.props.fetchAppuser(
                      session,
                      user?.appuserid,
                      callback => {},
                    );
                    this.props.fetchMembers(
                      this.props.session,
                      CallBackResponse => {
                        toast.success(
                          LocaleStrings.setting_manageseats_items_deletedsuccess,
                        );
                      },
                    );
                  });
                }
              },
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  _enable_disable_member(e, values) {
    let {session, user} = this.props;

    var data = {
      appuserid: values.appuserid,
      isactive: values.isactive == 1 ? 0 : 1,
      isverified: 1,
      invitestatus: 'accepted',
      role: values.role,
      clientid: values.clientid,
    };
    let title =
      values.isactive == 1
        ? LocaleStrings.setting_manageseats_items_disable_title
        : LocaleStrings.setting_manageseats_items_enable_title;
    let message =
      values.isactive == 1
        ? LocaleStrings.setting_manageseats_items_disable_desc
        : LocaleStrings.setting_manageseats_items_enable_desc;

    let toast_success =
      values.isactive == 1
        ? LocaleStrings.setting_manageseats_items_disablesuccess
        : LocaleStrings.setting_manageseats_items_enabledsuccess;

    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.updateMember(this.props.session, data, resource => {
              if (resource.success !== 0) {
                toast.success(toast_success);
                this.props.fetchAppuser(
                  session,
                  user?.appuserid,
                  callback => {},
                );
                this.props.fetchMembers(
                  this.props.session,
                  CallBackResponse => {},
                );
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }
  _resendInvite(e, values) {
    let {session} = this.props;
    let emailobj = {};
    emailobj.email = values.email;
    // emailobj.password = '12345678';

    this.props.sendEmailtoAppuser(session, emailobj, callback => {
      if (callback && callback.success == 1) {
        toast.success(LocaleStrings.setting_manageseats_items_invitesuccess);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  }

  handleindex = nmbr => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    var {values, keyindex, user, session} = this.props;
    // console.log("user", user);
    // console.log("session", session);
    let tabledate = '-';
    if (values.createdon !== null && values.createdon !== '') {
      // tabledate = moment(values.createdon).format("YYYY-MM-DD");
      tabledate = converDateIntoLocal(values.createdon).format('D MMM YYYY');
    }

    let indexisodd1 = this.handleindex(keyindex);

    return (
      <>
        <tr className={classNames(indexisodd1 ? 'bg-white' : 'chatbg', '')}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={values.avatar}
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = '../../images/user.png';
                  }}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">{values.name}</div>
                <div className="text-gray-500">{values.email}</div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {values.role == 'client' ? (
              'Admin'
            ) : (
              <>{_.startCase(values.role)}</>
            )}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {tabledate}
          </td>
          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">-</td> */}
          <td className="whitespace-nowrap pl-8 py-4 text-sm text-gray-500 text-center">
            {values.isactive == true && values.invitestatus == 'accepted' ? (
              <span className="inline-flex rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium leading-5 text-green027A48">
                <span
                  className="w-0.5 h-0.5 p-1 mx-2 mt-1.5"
                  style={{
                    background: 'green',
                    borderRadius: '50%',
                  }}></span>{' '}
                {LocaleStrings.setting_manageseats_items_enabled}
              </span>
            ) : values.isactive == false &&
              values.invitestatus == 'accepted' ? (
              <span className="inline-flex rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium leading-5 text-gray-800">
                <span
                  className="w-0.5 h-0.5 p-1 mx-2 mt-1.5"
                  style={{
                    background: '#667085',
                    borderRadius: '50%',
                  }}></span>{' '}
                Disabled
              </span>
            ) : (
              <span className="inline-flex rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium leading-5 text-gray-800">
                <span
                  className="w-0.5 h-0.5 p-1 mx-2 mt-1.5"
                  style={{
                    background: '#667085',
                    borderRadius: '50%',
                  }}></span>{' '}
                {LocaleStrings.setting_manageseats_items_invitation_sent}
              </span>
            )}
          </td>
          <td className="relative whitespace-nowrap py-4 pl-1 md:pl-3 text-right text-sm text-gray-500 mt-2 md:pr-6">
            <div className="flex align-center">
              {values.isactive == false && values.invitestatus == 'pending' ? (
                <img
                  onClick={e => this._resendInvite(e, values)}
                  className=" mt-2 cursor-pointer w-6 h-6"
                  src="../../images/refresh.png"></img>
              ) : (
                ''
              )}
              {values.role == 'member' ? (
                <>
                  {/* <div
                    className="btn-primary ml-2"
                    style={{ width: "120px" }}
                    onClick={(e) => this._enable_disable_member(e, values)}>
                    {values.isactive == 0 || values.isactive == false
                      ? "Enable"
                      : "Disable"}
                  </div> */}

                  {values.invitestatus == 'accepted' ? (
                    <Switch
                      checked={
                        values.isactive == 0 || values.isactive == false
                          ? false
                          : true
                      }
                      onClick={e => this._enable_disable_member(e, values)}
                      className={classNames(
                        values.isactive == 1 || values.isactive == true
                          ? 'bg-primary'
                          : 'bg-gray-200',
                        'relative m-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                      )}>
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          values.isactive == 1 || values.isactive == true
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        )}
                      />
                    </Switch>
                  ) : (
                    ''
                  )}

                  {/* Large Screen Delete Btn */}
                  <div className="ml-2 mt-2 cursor-pointer">
                    <img
                      className=""
                      onClick={e => this._deleteMember(e, values)}
                      src="../../images/delete.png"></img>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  fetchMembers,
  updateMember,
  fetchAppuser,
  sendEmailtoAppuser,
  deleteMembers,
  sendClientcredit,
})(MemberListitems);
