import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, reset} from 'redux-form';
import LocaleStrings from '@language';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {
  fetchMembers,
  addMember,
  addMembermodal,
  sendEmailtoAppuser,
} from '@manageseatAction';
import {fetchAppuser} from '@sidebarActions';
import {validateEmailForAppUser, validateDomain} from '@mainActions';

// import freeEmailDomains from '@assets/domains.json';

var generator = require('generate-password');
// const freeEmailDomains = require("free-email-domains");

class MemberAddEdit extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.reset();
    this.props.addMembermodal(false);
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    let {session, user} = this.props;
    let isrestrictedEmail = false;
    let emailExtention = '';
    if (
      !values.firstname ||
      values.lastname == '' ||
      !values.email ||
      values.email == '' ||
      values.role == ''
    ) {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
      return false;
    }

    if (values.email) {
      this.setState({btnLoader: true});
      this.setState({isBtnDisable: true});
      this.props.validateDomain(
        this.props.session,
        {email: values.email},
        response => {
          if (
            response.result &&
            response.success === 1 &&
            response.result.valid === 1
          ) {
            var genratepassword = generator.generate({
              length: 8,
              numbers: true,
              lowercase: true,
              uppercase: true,
            });

            let val = {};
            let emailobj = {};
            // val.emailtype = 'updateemail';
            val.clientid = user?.clientid;
            // val.afid = user?.afid;
            val.calledfrom = 'manageseat';
            val.name = values.firstname + ' ' + values.lastname;
            val.email = values.email;
            val.password = genratepassword;
            val.role = values.role;
            val.invitestatus = 'pending';
            val.isactive = 0;
            emailobj.email = values.email;
            emailobj.password = genratepassword;

            this.props.validateEmailForAppUser(
              this.props.session,
              val,
              emailresponse => {
                if (
                  emailresponse.result &&
                  emailresponse.success == 1 &&
                  emailresponse.result.available == 1
                ) {
                  this.props.addMember(this.props.session, val, response => {
                    if (response.success === 0) {
                      toast.error(
                        LocaleStrings.setting_manageseats_memberaddedit_addfail,
                      );
                      this.setState({btnLoader: false});
                      this.setState({isBtnDisable: false});
                    } else {
                      this.props.sendEmailtoAppuser(session, emailobj);
                      this.props.fetchAppuser(
                        session,
                        user?.appuserid,
                        callback => {},
                      );
                      this.props.fetchMembers(this.props.session, callback => {
                        toast.success(
                          LocaleStrings.setting_manageseats_memberaddedit_addsuccess,
                        );
                        this.setState({btnLoader: false});
                        this.setState({isBtnDisable: false});
                        this.props.reset();
                        this.props.addMembermodal(false);
                      });
                    }
                  });
                } else {
                  toast.error(
                    LocaleStrings.setting_manageseats_memberaddedit_emailexists,
                  );
                  this.setState({btnLoader: false});
                  this.setState({isBtnDisable: false});
                }
              },
            );
          } else {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            toast.error(
              'Please use a Business Email, free emails are blocked from the platform',
            );
          }
        },
      );
    }
  }

  render() {
    let {handleSubmit} = this.props;
    let departmentOpts = [];

    _.forEach(this.props.roles, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };
      departmentOpts.push(obj);
    });

    return (
      <>
        <Transition.Root show={this.props.isaddmembermodalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isaddmembermodalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-3/12 "
                  style={{padding: 0}}>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MODAL HEADER ENDS */}
                    <div className="p-6 border-t-2 border-quaternary">
                      <div className="pb-6">
                        <img
                          src="../../images/manageseats/invitemembers.png"
                          className=""></img>
                      </div>
                      <div className="pb-3 text-lg mainGray">
                        {LocaleStrings.setting_manageseats_memberaddedit_head}
                      </div>
                      <div className="pb-6 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.setting_manageseats_memberaddedit_subhead
                        }
                      </div>
                      <div className="pb-6">
                        <Field
                          name="firstname"
                          label={
                            LocaleStrings.setting_manageseats_memberaddedit_field1_label
                          }
                          placeholder={
                            LocaleStrings.setting_manageseats_memberaddedit_field1_placeholder
                          }
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-gray700"
                        />
                      </div>
                      <div className="pb-6">
                        <Field
                          name="lastname"
                          label={
                            LocaleStrings.setting_manageseats_memberaddedit_field4_label
                          }
                          placeholder={
                            LocaleStrings.setting_manageseats_memberaddedit_field4_placeholder
                          }
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-gray700"
                        />
                      </div>
                      <div className="pb-6">
                        <Field
                          name="email"
                          label={
                            LocaleStrings.setting_manageseats_memberaddedit_field2_label
                          }
                          placeholder={
                            LocaleStrings.setting_manageseats_memberaddedit_field2_placeholder
                          }
                          type="email"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-gray700"
                        />
                      </div>
                      {/* <div className="pb-6">
                        <Field
                          name="role"
                          label={
                            LocaleStrings.setting_manageseats_memberaddedit_field3_label
                          }
                          placeholder={
                            LocaleStrings.setting_manageseats_memberaddedit_field3_placeholder
                          }
                          mandatory="true"
                          component={this.renderSelect}
                          labelposition={LABEL_POSITION_TOP}
                          opts={departmentOpts}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          // value={this.props.initialValues.type}
                        />
                      </div> */}
                      <div className="flex">
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn-white text-primary cursor-pointer">
                          {LocaleStrings.cancel}
                        </button>
                        {/* Footer */}
                        <button
                          type="submit"
                          disabled={this.state.isBtnDisable}
                          className={classNames(
                            this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                            'btn-primary ml-3',
                          )}>
                          <svg
                            className={classNames(
                              this.state.btnLoader ? '' : 'hidden',
                              'animate-spin-medium h-5 w-5 rounded-full mx-2',
                            )}
                            disabled={this.state.isBtnDisable}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {
                            LocaleStrings.setting_manageseats_memberaddedit_btn_sendinvite
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var email = values['email'];
  var requiredFields = ['firstname', 'lastname', 'email', 'role'];
  requiredFields.forEach(field => {
    if (!values[field] || _.trim(values[field]) === '') {
      errors[field] = LocaleStrings.required;
    }
  });
  return errors;
}

function mapStateToProps(state) {
  var {session, user} = state;
  let initval = {
    role: 'member',
  };
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isaddmembermodalOpen: state.isaddmembermodalOpen,
    roles: state.roles,
    initialValues: initval,
  };
}

export default connect(mapStateToProps, {
  addMembermodal,
  fetchMembers,
  addMember,
  fetchAppuser,
  validateEmailForAppUser,
  sendEmailtoAppuser,
  validateDomain,
})(
  reduxForm({
    validate,
    form: 'addmanageSeatMember',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(MemberAddEdit),
);
