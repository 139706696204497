import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {BaseComponent} from '@baseComponent';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';
import {
  revealedbyappuserdata,
  revealedbyappusername,
  fetchrevealedContacts,
} from '@settingsUsageActions';

class UsagePlatformItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      btnLoader: false,
      endDate: props.endDate,
      startDate: props.startDate,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openRevealedmodal(e, values) {
    // let {startDate, endDate} = this.state;

    // this.props.revealedbyappuserdata(values);
    // this.props.revealedbyappusername(values.name);
    // this.props.fetchrevealedContacts(
    //   this.props.session,
    //   values.appuserid,
    //   1,
    //   {
    //     startDate,
    //     endDate,
    //   },
    //   res => {},
    // );

    // setTimeout(() => {
    // this.props.openRevealedmodal(true);
    // }, 300);

    this.props.openRevealedmodal(values);
  }

  handleindex = nmbr => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    var {values, keyindex} = this.props;

    // console.log('PROPS =>', values);

    let revealedcount = values.totalrevealedcount;
    let businessRevealedCount = values.busemailcount;
    let personalRevealedCount = values.personalemailcount;
    let phoneRevealedCount = values.directdialscount;
    // if (
    //   values.usercontact_by_revealedby &&
    //   values.usercontact_by_dialrevealedby
    // ) {

    //   revealedcount =
    //     parseInt(values.usercontact_by_revealedby.length) +
    //     parseInt(values.usercontact_by_dialrevealedby.length);
    // }

    /*if (values.usercontact_by_revealedby) {
      const personalEmails = values.usercontact_by_revealedby.filter(
        item => item.emails !== null,
      );

      personalRevealedCount = personalEmails.length;

      const businessEmails = values.usercontact_by_revealedby.filter(
        item => item.business_emails !== null,
      );

      businessRevealedCount = businessEmails.length;

      // console.log('personalRevealedCount => ', personalEmails.length);
      // console.log('businessEmailsRevealedCount => ', businessEmails.length);

      phoneRevealedCount = parseInt(
        values.usercontact_by_dialrevealedby.length,
      );

      revealedcount =
        personalRevealedCount + businessRevealedCount + phoneRevealedCount;
    }*/
    let indexisodd1 = this.handleindex(keyindex);
    return (
      <>
        <tr className={classNames(indexisodd1 ? 'bg-white' : 'chatbg', '')}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm ">
            <div>{values.name}</div>
            <div className="text-gray-500">{values.email}</div>
          </td>
          <td
            className={classNames(
              'whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium text-center',
              revealedcount > 0 ? 'cursor-pointer' : 'cursor-none',
            )}>
            {revealedcount}
            {revealedcount > 0 ? (
              <div
                className="text-primary"
                onClick={e => this.openRevealedmodal(e, values)}>
                {LocaleStrings.setting_usage_view_contact}
              </div>
            ) : null}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-center">
            {/* {values.emailcount} */}
            {personalRevealedCount}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-center">
            {/* {values.emailcount} */}
            {businessRevealedCount}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer text-center">
            {phoneRevealedCount}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  revealedbyappuserdata,
  revealedbyappusername,
  fetchrevealedContacts,
})(UsagePlatformItem);
