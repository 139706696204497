import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import {Field, reduxForm} from 'redux-form';
import toast from 'react-hot-toast';
import {classNames, isEmpty} from '@commonFunction';
import Dropzone from 'react-dropzone';
import BaseLoader from '@baseLoader';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {
  fetchmyaccountData,
  uploadImage,
  updateAppuser,
  updateuserEmail,
  updateEmailModal,
  updateEmailcode,
} from '@settingsMyaccountActions';
import {fetchAppuser} from '@sidebarActions';
import UpdateUserEmail from './updateemail';

class Myaccounts extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_myaccounts,
      phone: '',
      country: '',
      btnLoader: false,
      isBtnDisable: false,
      btnLoaderupdate: false,
      isBtnDisableupdate: false,
      fileUrl: '',
      fileArrNew: {},
      code: '',
    };
    // All Binded Functions
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {
    let {user, session} = this.props;

    let appuserid = user?.appuserid;
    var url_string = window.location.href;
    var query = url_string.split('?')[1];

    var params = {};
    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
        // params[pair[1]] = decodeURIComponent(pair[1]);
      }
      console.log('Params');

      if (params && params.token && params.token !== '') {
        this.props.updateEmailcode({email: params.email, code: params.token});
        this.setState({code: params});
        let e = '';
        this.openeditEmailmodal(e);
      }
    }
    this.props.fetchmyaccountData(session, '', appuserid, callback => {
      this.setState({loading: false});
    });
  }

  onSubmitForm(values) {
    let {initialValues, user, session} = this.props;
    let appuserid = user?.appuserid;
    this.setState({btnLoader: true});
    this.setState({isBtnDisable: true});
    if (initialValues.email !== values.email) {
    } else {
      delete values['avatar'];
      if (
        values.phonenumber &&
        values.phonenumber !== null &&
        values.phonenumber !== ''
      ) {
        values.phonenumber = values.phonenumber.replace(/[- )( +]/g, '');
      }
      // this.setState({ loading: true });
      if (values.firstname && values.lastname) {
        values.name = values.firstname + ' ' + values.lastname;
      }
      //   delete values['firstname'];
      //   delete values['lastname'];

      this.props.updateAppuser(this.props.session, values, response => {
        // console.log("response :- ", response);
        if (response.success === 0) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          toast.error(LocaleStrings.something_went_wrong);
        } else {
          this.props.fetchmyaccountData(session, '', appuserid, callback => {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            toast.success(LocaleStrings.updated_successfully);
          });

          // alert("success");
        }
      });
    }
  }

  _phoneChange = (value, country, e, formattedValue) => {
    this.setState({phone: value, country: country.dialCode});
  };

  _handleDeleteimage(e) {
    let {initialValues} = this.props;
    this.setState({fileArrNew: {}});
    this.setState({fileUrl: ''});
  }
  // Add File
  onDrop = async files => {
    this.setState({imageLoader: true});

    let file = files[0];
    let filearr = [];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = e => {
      file.url = e.target.result;
      filearr.push(file);
      this.setState({imageLoader: false});
    };

    this.setState({fileArrNew: filearr});
    this.setState({fileUrl: URL.createObjectURL(file)});
  };

  _handleUpdateimage(e, values) {
    let {user, session, initialValues} = this.props;
    // console.log("initialValues", initialValues);
    if (Object.keys(this.state.fileArrNew).length > 0) {
      this.setState({btnLoaderupdate: true});
      this.setState({isBtnDisableupdate: true});
      let resource_coverimage = '';
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];
      // Image Exist
      let fileArrNew = this.state.fileArrNew[0];

      let ts = new Date().getTime(); // Timestamp

      let medianame = initialValues.appuserid + '.' + 'png'; // Image Name Creation

      resource_coverimage = medianame;

      let ext = fileArrNew.name.split('.').pop(); // File extension

      let coverJson = {
        // type: "cover",
        type: 'image',
        name: medianame,
        media: fileArrNew.url,
      };
      filetobeupload.push(coverJson);
      let counter = filetobeupload.length;

      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadImage(this.props.session, item, response => {
              if (response.success === 0) {
                resolve();
              } else {
                uploaderCounter++;
                resolve();
              }
            });
          }),
        );
      });

      let updateImageobj = {
        avatar: medianame,
        role: initialValues.role,
        appuserid: initialValues.appuserid,
        clientid: initialValues.clientid,
      };

      Promise.all(arrPromise).then(() => {
        this.props.updateAppuser(
          this.props.session,
          updateImageobj,
          response => {
            if (response.success === 0) {
              this.setState({btnLoaderupdate: false});
              this.setState({isBtnDisableupdate: false});
              toast.error(LocaleStrings.something_went_wrong);
            } else {
              let appuserid = user?.appuserid;
              this.props.fetchmyaccountData(
                session,
                '',
                appuserid,
                callback => {
                  this.setState({fileArrNew: {}});
                  this.setState({fileUrl: ''});
                  this.setState({btnLoaderupdate: false});
                  this.setState({isBtnDisableupdate: false});
                  toast.success(LocaleStrings.settings_myaacounts_img_success);
                },
              );
              this.props.fetchAppuser(
                session,
                this.props.user.appuserid,
                callback => {},
              );
            }
          },
        );
      });
    } else {
      toast.error(LocaleStrings.settings_myaacounts_img_error);
    }
  }
  openeditEmailmodal() {
    this.props.updateEmailModal(true);
  }

  render() {
    let {handleSubmit, initialValues, myaccountdataList, session} = this.props;
    let {fileUrl, code} = this.state;

    let coverimage = '';

    if (isEmpty(fileUrl)) {
      coverimage = initialValues?.avatar;
    } else {
      coverimage = fileUrl;
    }
    let countryOpts = [];

    _.forEach(this.props.countryList, function (value) {
      var obj = {
        name: value.name,
        value: value.name,
      };

      countryOpts.push(obj);
    });

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <form
              className="pt-0 p-6"
              onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
              <div className="block md:flex pb-10 border-b border-gray-200">
                <div>
                  <div className="text-mainGray text-lg font-medium">
                    {LocaleStrings.settings_myaacounts_header}
                  </div>
                  <div className="mt-2 text-gray500 text-sm font-normal">
                    {LocaleStrings.settings_myaacounts_subheader}
                  </div>
                </div>
                <div className="ml-auto xs:mt-4 md:mt-0">
                  <div className="flex">
                    <button
                      type="submit"
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                        'btn-primary ml-4 cursor-pointer',
                      )}>
                      <svg
                        className={classNames(
                          this.state.btnLoader ? '' : 'hidden',
                          'animate-spin-medium h-5 w-5 rounded-full mx-2',
                        )}
                        disabled={this.state.isBtnDisable}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.settings_myaacounts_btnupdate}
                    </button>
                  </div>
                </div>
              </div>
              <div className="block lg:flex pt-6">
                <div className="lg:w-3/12">
                  <div className="text-gray700 text-sm font-medium">
                    {LocaleStrings.settings_myaacounts_basicdetails}
                  </div>
                  <div className="mt-2 text-gray500 text-sm font-normal">
                    {LocaleStrings.settings_myaacounts_displaytext}
                  </div>
                </div>
                <div className="lg:w-8/12 lg:px-24">
                  <div>
                    <Field
                      name="firstname"
                      label="First Name"
                      placeholder="Enter First Name"
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-normal text-secondary"
                    />
                  </div>
                  <div className="mt-4">
                    <Field
                      name="lastname"
                      label="Last Name"
                      placeholder="Enter Last Name"
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-normal text-secondary"
                    />
                  </div>
                </div>
              </div>
              <div className="block lg:flex pb-6 border-b border-gray-200">
                <div className="lg:w-3/12"></div>
                <div className="lg:w-8/12 lg:px-24">
                  <div className="mt-4">
                    <Field
                      name="email"
                      label={
                        LocaleStrings.settings_myaacounts_field_businessemail
                      }
                      placeholder={
                        LocaleStrings.settings_myaacounts_field_businessemail
                      }
                      type="text"
                      isdisabled="true"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-normal text-secondary"
                    />
                  </div>
                </div>
                <div className="lg:w-1/12 mt-12">
                  {this.props.socialLoginUsed ? null : (
                    <div
                      type=""
                      className="cursor-pointer text-primary"
                      onClick={e => this.openeditEmailmodal(e)}>
                      {LocaleStrings.settings_myaacounts_change_emailbtn}
                    </div>
                  )}
                </div>
              </div>
              <div className="block lg:flex py-6 border-b border-gray-200">
                <div className="lg:w-3/12">
                  <div className="text-gray700 text-sm font-medium">
                    {LocaleStrings.settings_myaacounts_yourphoto}
                  </div>
                  <div className="mt-2 text-gray500 text-sm font-normal">
                    {LocaleStrings.settings_myaacounts_displaytext}
                  </div>
                </div>
                <div className="dropzone-round ml-14 mt-4 sm:mt-0" key={`drop`}>
                  <div className="img_wrpcover relative">
                    <img
                      loading="lazy"
                      src={coverimage}
                      className="mx-auto h-10 w-10 rounded-full md:w-20 md:h-20"
                      alt=""
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = '../..//images/user.png';
                      }}
                    />
                  </div>
                  <Dropzone
                    onDrop={e => this.onDrop(e)}
                    accept="image/jpeg, image/png ,image.jpg"
                    multiple={false}>
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps()}
                        className="cursor-pointer "
                        align="center">
                        <input {...getInputProps()} />
                        {this.state.imageLoader ? (
                          <svg
                            className={classNames(
                              this.state.imageLoader ? '' : 'hidden',
                              'animate-spin-medium h-5 w-5 rounded-full mx-2',
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          <p className="paragraph-seconday text-xs ">
                            {LocaleStrings.drop_or_select}
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="ml-auto mr-6">
                  <div className="flex">
                    {isEmpty(fileUrl) ? (
                      ''
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={e => this._handleDeleteimage(e)}>
                        {LocaleStrings.settings_myaacounts_deletebtn}
                      </div>
                    )}
                    <div
                      className={classNames(
                        this.state.isBtnDisableupdate
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer',
                        'text-primary ml-12 sm:ml-4 mt-4 sm:mt-0',
                      )}
                      onClick={e => this._handleUpdateimage(e)}>
                      <svg
                        className={classNames(
                          this.state.btnLoaderupdate ? '' : 'hidden',
                          'animate-spin-medium h-5 w-5  rounded-full mx-2',
                        )}
                        disabled={this.state.isBtnDisableupdate}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.settings_myaacounts_btnupdate}
                    </div>
                  </div>
                </div>
              </div>
              {myaccountdataList && myaccountdataList.role == 'client' ? (
                <>
                  <div className="block lg:flex py-6 border-b border-gray-200">
                    <div className="lg:w-3/12">
                      <div className="text-gray700 text-sm font-medium">
                        {LocaleStrings.settings_myaacounts_businessdetails}
                      </div>
                      <div className="mt-2 text-gray500 text-sm font-normal">
                        {
                          LocaleStrings.settings_myaacounts_businessdetails_subtitle
                        }
                      </div>
                    </div>
                    <div className="lg:w-8/12 lg:px-24">
                      <div>
                        <Field
                          name="companyname"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_company
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_placeholder_company
                          }
                          type="text"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-secondary"
                        />
                      </div>
                      <div className="mt-4">
                        <Field
                          name="phonenumber"
                          label={LocaleStrings.settings_myaacounts_field_phone}
                          type="text"
                          component={this.renderPhonewithFlag}
                          className="cust-input-field"
                          classNameLabel="text-box-lable"
                          phone={this.state.phone}
                          phoneChange={this._phoneChange}
                        />
                      </div>
                      <div className="mt-4">
                        <Field
                          name="country"
                          label={
                            LocaleStrings.settings_myaacounts_field_country
                          }
                          placeholder={'Select Country'}
                          component={this.renderSelect}
                          labelposition={LABEL_POSITION_TOP}
                          opts={countryOpts}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                        />
                      </div>
                      <div className="mt-4">
                        <Field
                          name="salespeoplecount"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_salespeoplecount
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_label_salespeoplecount
                          }
                          type="number"
                          min="0"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-secondary"
                        />
                      </div>
                      <div className="mt-4">
                        <Field
                          name="requiredleadcount"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_leadcountrequired
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_label_leadcountrequired
                          }
                          type="number"
                          min="0"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-secondary"
                        />
                      </div>
                      <div className="mt-4">
                        <Field
                          name="department"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_department
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_label_department
                          }
                          type="text"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-secondary"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="block lg:flex py-6 border-b border-gray-200">
                    <div className="lg:w-3/12">
                      <div className="text-gray700 text-sm font-medium">
                        {LocaleStrings.settings_myaacounts_taxbillingheader}
                      </div>
                      <div className="mt-2 text-gray500 text-sm font-normal">
                        {LocaleStrings.settings_myaacounts_taxbilling_subtitle}
                      </div>
                    </div>
                    <div className="lg:w-8/12 lg:px-24">
                      <div>
                        <Field
                          name="taxid"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_taxid
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_placeholder_taxid
                          }
                          type="text"
                          component={this.renderFieldText}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-secondary"
                        />
                      </div>
                      <div className="mt-4">
                        <Field
                          name="billingaddress"
                          label={
                            LocaleStrings.settings_myaacounts_field_label_billingaddress
                          }
                          placeholder={
                            LocaleStrings.settings_myaacounts_field_placeholder_billingaddress
                          }
                          component={this.renderFieldTextarea}
                          maxlength={200}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-box-lable"
                          className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </form>
          </>
        )}
        <UpdateUserEmail history={this.props.history} />
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = [];

  requiredFields = ['firstname', 'lastname', 'email'];

  requiredFields.forEach(field => {
    if (!values[field] || _.trim(values[field]) === '') {
      errors[field] = LocaleStrings.required;
    }
  });
  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user, socialLoginType} = state;
  return {
    session,
    user,
    myaccountdataList: state.myaccountdataList?.data,
    initialValues: state.myaccountdataList?.data,
    countryList: state.countryList,
    socialLoginUsed: socialLoginType,
  };
}

export default connect(mapStateToProps, {
  fetchmyaccountData,
  uploadImage,
  updateAppuser,
  fetchAppuser,
  updateuserEmail,
  updateEmailModal,
  updateEmailcode,
})(
  reduxForm({
    validate,
    form: 'MyaccountsForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Myaccounts),
);
