import axios from 'axios';
import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
  MAIN_URL,
} from '@constant';

import LocaleStrings from '@language';
import {ENV} from '../../../../common/constants';

// All Exports
export var ALL_SUBSCRIPTION_PLANS = 'ALL_SUBSCRIPTION_PLANS';
export var MEMBER_AND_ADMINLISTCOUNT = 'MEMBER_AND_ADMINLISTCOUNT';
export var APIKEYS_COUNT = 'APIKEYS_COUNT';

export function fetchSubscriptionplan(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/subscriptionplan?filter=(isaddon=0)&order=displayorder%20ASC`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        dispatch({
          type: ALL_SUBSCRIPTION_PLANS,
          payload: {data: data.resource},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}

export function upgradePlan(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/upgradeplan`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function subscription(session, values, callback) {
  var config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type',
      'Content-Type': 'application/json',
    },
  };
  var url = `${MAIN_URL}stripe/index.php`;
  var body = {...values, env: ENV};
  var response = axios.post(url, body, config);

  return dispatch => {
    response
      .then(({data}) => {
        callback({success: 1, resource: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function fetchPlanInvoice(session, values, callback) {
  var config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type',
      'Content-Type': 'application/json',
    },
  };
  var url = `${MAIN_URL}stripe/index.php?${values}&env=${ENV}`;
  var response = axios.get(url, null, config);

  return dispatch => {
    response
      .then(({data}) => {
        if (typeof data === 'object' && Object.keys(data).length > 0) {
          callback({success: 1, resource: data});
        } else {
          let regex = /No such subscription: '([^']+)'/;
          let match = data.match(regex);
          if (match) {
            callback({success: 2});
          } else {
            callback({success: 0});
          }
        }
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function updatePlan(session, values, callback) {
  var config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type',
      'Content-Type': 'application/json',
    },
  };
  var url = `${MAIN_URL}stripe/index.php`;
  var body = {...values, env: ENV};
  var response = axios.patch(url, body, config);

  return dispatch => {
    response
      .then(({data}) => {
        callback({success: 1, resource: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function cancelPlan(session, values, callback) {
  var config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type',
      'Content-Type': 'application/json',
    },
  };
  var url = `${MAIN_URL}stripe/index.php?${values}&env=${ENV}`;
  // var body = { ...values };
  var response = axios.delete(url, null, config);

  return dispatch => {
    response
      .then(({data}) => {
        callback({success: 1, resource: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function fetchCurrentMemberCount(session, callback) {
  let orderBy = `createdon%20DESC`;
  // var filter = encodeURI(`filter=((role=admin)OR(role=member))AND(isdeleted=0)`);
  var filter = encodeURI(`filter=(isdeleted=0)`);
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser?${filter}&order=${orderBy}&include_count=true`;
  // console.log(url)
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        // console.log('data1', data);
        dispatch({
          type: MEMBER_AND_ADMINLISTCOUNT,
          payload: {data: data.meta.count},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}

export function fetchApikeyCount(session, callback) {
  var filter = encodeURI(`filter=(isactive=1)AND(isdeleted=0)`);
  let orderBy = `createdon%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/apikeys?${filter}&order=${orderBy}&include_count=true`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        dispatch({
          type: APIKEYS_COUNT,
          payload: {data: data.meta.count},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}

export function updateUsersubscription(session, obj, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/usersubscription`;
  let body = {resource: obj};
  return dispatch => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}
