import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';
import axios from 'axios';
import {APP_API_KEY, INSTANCE_URL, MAIN_URL} from '@constant';

import LocaleStrings from '@language';

// All Exports

export var CREATEAPI_MODAL = 'CREATEAPI_MODAL';
export var TRYOUTAPI_MODAL = 'TRYOUTAPI_MODAL';
export var DOCUMENTATION_MODAL = 'DOCUMENTATION_MODAL';
export var APIKEYS_DATALIST = 'APIKEYS_DATALIST';
export var EDITAPIDATA = 'EDITAPIDATA';

/**** Open add createapi modal ****/
export function createApimodal(isOpen) {
  return {type: CREATEAPI_MODAL, payload: isOpen};
}

/**** Open add documentation modal ****/
export function apiDocumentationmodal(isOpen) {
  return {type: DOCUMENTATION_MODAL, payload: isOpen};
}

/**** Open tryout modal ****/
export function tryoutapiModal(isOpen) {
  return {type: TRYOUTAPI_MODAL, payload: isOpen};
}

export function fetchApikeys(session, callback) {
  var filter = encodeURI(`filter=(isactive=1)AND(isdeleted=0)`);
  let orderBy = `createdon%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/apikeys?${filter}&order=${orderBy}`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        dispatch({
          type: APIKEYS_DATALIST,
          payload: {data: data.resource},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}

export function createApi(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/apikeys`;
  // let body = { resource: { ...values } };
  let body = {resource: [values]};
  return dispatch => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function onEditapi(values) {
  return dispatch => {
    dispatch({type: EDITAPIDATA, payload: values});
  };
}

export function updateApi(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/apikeys`;
  let body = {resource: {...values}};
  // let body = {resource: values };
  return dispatch => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function callgetcontactApi(session, values, callback) {
  let config = {
    headers: {
      'Access-Control-Allow-Origin': 'https://prepro.kipplo.com',
      'Access-Control-Allow-Methods': 'GET',
      'X-Api-Key': values.apikey,
      'X-ApiFlo-Session-Token': session.sessionToken,
      'Access-Control-Allow-Headers': 'Origin, Content-Type',
      'Content-Type': 'application/json',
    },
  };
  // var url = `${MAIN_URL}api/index.php?linkedinurl=${values.linkedinurl}&apikey=${values.apikey}&type=${values.type}&sessiontoken=${session.sessionToken}`;
  var url = `${MAIN_URL}apikipplo/index.php?linkedinurl=${values.linkedinurl}&apikey=${values.apikey}&type=${values.type}`;
  var response = axios.get(url, null, config);

  return dispatch => {
    response
      .then(({data}) => {
        callback({success: 1, data: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}
